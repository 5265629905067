































import PaymentInput from '@/components/PaymentInput.vue';
import { defineComponent } from '@/plugins/composition';

export default defineComponent({
  components: { PaymentInput },
  name: 'PaymentForm',
});
