







































































import BaseButton from '@/components/base/BaseButton.vue';
import { defineComponent, ref } from '@/plugins/composition';
import RegisterText from './RegisterText.vue';
import { useFormValidation } from '@/composables/useValidation';
import { rules } from '@/composables/useValidation/validations';
import { debouncedWatch } from '@vueuse/core';
import { User } from '@/models';
import { routeNames } from '@/router/routeNames';
import { useLoader } from '@/composables/useLoader';
import { useRegistrationData } from '@/composables/useRegistrationData';
import { mdiEyeOutline } from '@mdi/js';
import { useApi } from '@/composables/useApi';
import { snakeKeys } from '@/utils/case';
import { useAuthStore } from '@/composables/useAuthStore';
import { useRouter } from '@/router/useRouter';

export default defineComponent({
  components: { RegisterText, BaseButton },
  name: 'RegisterForm',
  setup() {
    const valid = ref(false);
    const isPasswordVisible = ref(false);
    const { loading, withLoader } = useLoader();
    const { artforms, accountType, formData, type, typeSelected, isForOrganisation, emailExists, next } =
      useRegistrationData();
    const { post } = useApi();
    const { router } = useRouter();
    const { errors, mapErrors } = useFormValidation(formData);
    const checkIfEmailExists = async () =>
      withLoader(async () => {
        emailExists.value = await User.existsByEmail(formData.email);
      });

    const register = async () => {
      withLoader(async () => {
        const response = await post('/users', snakeKeys(formData)).catch((errors) => mapErrors(errors));
        if (response !== undefined) {
          await login(formData.email, formData.password);
        }
      });
    };

    const login = async (email: string, password: string) =>
      withLoader(async () => {
        const user = await User.login({ email, password });
        if (user) {

          const { storeLogin, setLocalToken } = useAuthStore();
          await storeLogin(user);
          const tokenReturn = user.data.token;
          setLocalToken(tokenReturn);

          setTimeout(() => {
            router.push({ name: routeNames.subscriber });
          }, 1000);
          if (window) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }
        }
      });

    debouncedWatch(
      () => formData.email,
      async () => checkIfEmailExists(),
      { debounce: 500, immediate: true }
    );
    return {
      type,
      errors,
      isForOrganisation,
      typeSelected,
      formData,
      rules,
      valid,
      accountType,
      artforms,
      loading,
      emailExists,
      register,

      routeNames,
      isPasswordVisible,
      icons: {
        mdiEyeOutline,
      },
    };
  },
});
